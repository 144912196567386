/* @jsx jsx */
import React from 'react';
import { jsx } from '../../provider';

const LogoIcon = (props) => {
  const {
    sx,
    rotate = 0,
    secondaryColor = '#000000',
    primaryColor = '#405567',
  } = props;
  const rotateDeg = `rotate(${parseInt(rotate)})`;

  return (
    <svg
      sx={sx}
      focusable="false"
      viewBox="0 0 400 400"
      transform={rotateDeg}
      ariaHidden="true"
    >
      <g id="logo_svg">
        <g transform="scale(1.5 1.5)">
          <g>
            <g>
              <path
                fill={primaryColor}
                d="M140.704,175.844c-10.467-25.814-27.695-49.358-52.712-71.948l-6.573-5.953l7.632,4.497
            c5.528,3.236,11.286,8.098,16.399,12.394c2.664,2.241,5.183,4.36,7.489,6.114c18.546,19.849,31.776,39.971,40.512,61.604l0,0
            c7.289,17.968,10.869,35.642,12.173,53.073c3.164-28.283-2.925-72.652-17.444-94.471c-23.113-35.543-101.33-72.5-101.33-72.5
            C62.302,99.498,55,135.551,65.238,167.49c26.077,52.097,63.752,55.573,87.612,67.685c-0.732-19.549-4.056-39.347-12.142-59.315
            C140.704,175.844,140.704,175.844,140.704,175.844z"
              />
            </g>
            <g>
              <path
                fill={primaryColor}
                d="M184.498,122.74c-9.737,14.649-13.421,45.584-10.354,62.832c7.717-26.75,16.552-53.575,39.022-67.55
            c-12,18.044-19.046,40.019-25.203,60.659c14.693-5.863,34.254-11.255,48.385-39.471c6.403-19.977,1.822-42.512,11.488-61.779
            C247.835,77.429,198.948,100.521,184.498,122.74z"
              />
            </g>
            <path
              fill={secondaryColor}
              d="M158.606,122.516c-1.076-6.53-0.685-13.281,0.499-19.796c1.175-6.537,3.179-12.916,5.76-19.034
          c2.592-6.114,5.771-11.963,9.536-17.385c3.753-5.448,8.064-10.553,13.117-14.784c-3.392,5.657-6.616,11.218-9.596,16.906
          c-2.964,5.685-5.726,11.442-8.218,17.327c-2.492,5.863-4.698,11.836-6.616,17.936
          C161.211,109.792,159.708,116.007,158.606,122.516z"
            />
            <path
              fill={secondaryColor}
              d="M182.119,228.873c1.401-8.257,5.382-15.937,10.053-22.857c4.694-6.982,10.31-13.244,16.312-19.046
          c3.063-2.85,6.186-5.607,9.434-8.219c3.307-2.549,6.648-5.071,10.169-7.312c7.002-4.525,14.433-8.547,22.411-10.938
          c-7.047,4.389-13.621,9.222-20.021,14.278c-3.15,2.568-6.345,5.102-9.364,7.81c-3.067,2.642-6.085,5.355-8.995,8.19
          c-2.993,2.767-5.828,5.639-8.602,8.602c-2.842,2.903-5.461,5.983-8.081,9.07C190.326,214.73,185.601,221.33,182.119,228.873z"
            />
            <path
              fill={secondaryColor}
              d="M40.123,181.25c6.616,5.083,12.788,10.611,18.932,16.067c3.107,2.696,6.129,5.48,9.251,8.127
          c3.079,2.698,6.172,5.37,9.336,7.927c3.122,2.612,6.344,5.117,9.596,7.516c3.235,2.476,6.602,4.734,9.998,6.953
          c6.843,4.375,14.031,8.184,21.778,11.263c-8.293-0.945-16.353-3.841-23.815-7.696c-3.779-1.885-7.333-4.138-10.839-6.446
          c-3.465-2.369-6.788-4.917-10.04-7.538c-3.207-2.677-6.329-5.443-9.365-8.299c-2.965-2.902-5.958-5.818-8.749-8.887
          C50.532,194.179,45.192,187.858,40.123,181.25z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
